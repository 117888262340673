import Instagram from './_parts/instagram';
import Slider from './_parts/slider';
import News from './_parts/news';
import AnimateDeco from './_parts/animateDeco';

class Page{
    constructor() {
    }
    init(){
        this.instagram = new Instagram();
        this.instagram.init();

        this.slider = new Slider();
        this.slider.init();

        this.news = new News();
        this.news.init();

        this.animateDeco = new AnimateDeco();
        this.animateDeco.init();
    }
}

var page = new Page();
page.init();
