export default class {
    constructor() {
        this.$el = $('.visual_slide');
        this.$logo = $('.visual_logo');
        this.startAt = 0;
        this.slickOption = {
            dots: true,
            fade: true,
            autoplay: true,
            autoplaySpeed: 7000,
            initialSlide: this.startAt,
            prevArrow: '<a href="#" class="visual_slide_arrow -prev"><i class="m-icon-arrow_l"></i></a>',
            nextArrow: '<a href="#" class="visual_slide_arrow -next"><i class="m-icon-arrow_r"></i></a>'
        }
    }
    init() {
        this.setConts();
        this.setEvent();
    }
    setConts(){
        this.$logo.css({'transform': 'translateY(5px)'})
    }
    setEvent(){
        this.$el
            .on('init', (event, slick)=>{
                this.checkLogoState(this.startAt);
            });

        this.$el.slick(this.slickOption);

        this.$el
            .on('beforeChange', (event, slick, currentSlide, nextSlide)=>{
                this.checkLogoState(nextSlide);
            });

        this.$el
            .on('afterChange', (event, slick, currentSlide)=>{
                this.checkLogoState(currentSlide);
            });
    }
    checkLogoState(num){
        this.logo = $('.visual_slide .visual_slide_item').eq(num).hasClass('-noLogo');

        if (this.logo) {
            this.hideLogo();
        } else {
            this.showLogo();
        }
    }
    showLogo(){
        TweenMax.to(this.$logo, .5, {
            ease: Power1.easeOut,
            'transform': 'translateY(0)',
            opacity: 1,
            onStart: ()=>{
                this.$logo.css({'display': 'block'})
            }
        });
    }
    hideLogo(){
        TweenMax.to(this.$logo, .5, {
            ease: Power1.easeOut,
            'transform': 'translateY(5px)',
            opacity: 0,
            onComplete: ()=>{
                this.$logo.css({'display': 'none'})
            }
        });
    }
}
