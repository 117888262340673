export default class {
    constructor() {
        this.$bgItem = $('.bgItem');
        this.$snsBgItem = $('.sns_bg_item');
    }
    init() {
        this.setConts();

        $(window)
            .on('load',()=>{
                setTimeout(()=>{
                    this.showConts();
                },500);
            });
    }
    setConts(){
        this.$bgItem.each((i, el)=>{
            if ($(el).hasClass('-left')) {

                $(el).find('img').css({
                    'transform': 'translateX(-150%)',
                    'opacity': '0'
                })

            } else if ($(el).hasClass('-right')) {

                $(el).find('img').css({
                    'transform': 'translateX(150%)',
                    'opacity': '0'
                })

            }
        });

        var delay = 0;
        for (var i = 0; i < this.$snsBgItem.length; i++) {
            if (i == 0 || i == 2 || i == 4 || i == 6) {
                this.move = 50;
            } else {
                this.move = -50;
            }

            TweenMax.to(this.$snsBgItem.eq(i), 12, {
                ease: Power1.easeInOut,
                x: this.move,
                repeat: -1,
                yoyo : true,
                delay: delay
            });

            delay += .3;
        }
    }
    showConts(){
        this.$bgItem
            .on('inview', (e)=>{
                var $el = $(e.currentTarget),
                    $el_pic = $el.find('img'),
                    delay = .5;

                for (var i = 0; i < $el_pic.length; i++) {
                    TweenMax.to($el_pic.eq(i), 2.2, {
                        ease: Power4.easeOut,
                        'opacity': 1,
                        'transform': 'translateX(0)',
                        delay: delay
                    });

                    delay += .7;
                }
            });
    }
}
