export default class {
    constructor() {
        this.max = 12;
        this.jsonL = 10;

        this.$container = $('#instagram');
    }
    init() {
        this.loadJson();
    }
    loadJson(){
        this.token = '4927924407.ef362ff.0d355cc53b9247ec9a985fc2059dcd47';
        this.userID = '2169440822';

        $.ajax({
            url: 'https://api.instagram.com/v1/users/' + this.userID + '/media/recent',
            data: { access_token: this.token },
            dataType: 'jsonp',
            error: (jqXHR, textStatus, errorThrown)=>{
                console.log('error');
            },
            success: (data, textStatus, jqXHR)=>{
                this.setConts(data.data);
            }
        });
    }
    setConts(d){
        for (var i = 0; i < this.max; i++) {
            if (d[i].caption == null) {
                continue;
            }

            this.$container
                .append(
                    '<li>'+
                        '<a href="' + d[i].link + '" target="_blank" style="background-image:url(' + d[i].images.low_resolution.url + ')">'+
                            '<p><span>' + d[i].caption.text + '</span></p>'+
                        '</a>'+
                    '</li>'
                );
        }
    }
}
