export default class {
    constructor() {
        this.json = null;
        this.$container = $('.news_list');
    }
    init() {
        this.loadJson();
    }
    loadJson(){
        $.ajaxSetup({
            cache: false,
        });
        $.ajax({
            type: 'GET',
            url: "/assets/json/news.json",
            dataType: 'json',
            success: (json)=> {
                this.json = json;

                this.setConts();
            },
            error: ()=> {
                console.log('GET JSON ERROR!');
            }
        });
    }
    setConts(){
        this.counter = 0;
        this.max = 4;

        for (var i = this.json.length-1; i >= 0; i--) {
            if (this.counter > this.max - 1) {
                break;
            }

            this.$container
                .append(
                    '<li class="news_item">'+
                        '<p class="news_item_head">' + this.json[i].date + '</p>'+
                        '<p class="news_item_body">' + this.json[i].title + '</p>'+
                    '</li>'
                );

            this.counter++;
        }
    }
}
